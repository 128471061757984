
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '@/store/account';
import { useMenuStore } from '@/store/menu';
import { ICompanyInfoItem } from '@/api/account/type';
import { ElMessage } from 'element-plus';
import { SYSTEM_INFO_ENUM } from '@/constant/system-info';

export default defineComponent({
  components: {
    // SelectCompanyDialog,
  },
  setup() {
    const loading = ref(false);
    const $route = useRoute();
    const $router = useRouter();
    const { query } = $route;
    const { account, setCurrentCompany } = useAccountStore();
    const { getMenus, clearMenus } = useMenuStore();
    const selectedCompanyId = ref<string>('');
    const companyInfoList = computed<ICompanyInfoItem[]>(() => {
      return account?.companyInfoList || [];
    });
    const handleConfirm = async () => {
      try {
        if (loading.value) return;
        if (!selectedCompanyId.value) {
          ElMessage.error('请选择租户');
          return;
        }
        loading.value = true;
        const currentCompany = companyInfoList.value.find(it => it.companyId === selectedCompanyId.value);
        if (currentCompany) {
          setCurrentCompany(currentCompany);
          clearMenus();
          await getMenus();
        }
        const path = query.redirect as string|null;
        if (path) {
          $router.replace({ path: decodeURIComponent(path) });
        } else {
          $router.replace({
            name: 'DomesticGoods',
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,
      handleConfirm,
      companyInfoList,
      selectedCompanyId,
      systemName: SYSTEM_INFO_ENUM.SYSTEM_NAME,
    };
  },
});
